export default class Cookies {
	public $wrapper: HTMLElement;
	public $agree: HTMLElement;

	private today: Date = new Date();
	private name: string = 'orka@cookies';

	constructor(isDesktop: boolean) {
		this.$wrapper = document.querySelector('[data-cookies]') as HTMLElement;
		this.$agree = document.querySelector('[data-cookies]') as HTMLElement;

		if (this.getCookie(this.name)) {
			return;
		}

		this.$agree.addEventListener('click', () => {
			this.setCookie(this.name, +new Date());

			this.$wrapper.classList.remove('is-visible');
		});

		setTimeout(() => {
			this.$wrapper.classList.add('is-visible');
		}, isDesktop ? 6000: 3500);
	}

	private setCookie(name: string, value: any, options: { expires: number } | null = null): void {
		options = {...(options || {}), ...{expires: 30}};

		if (this.getCookie(name)) {
			this.removeCookie(name);
		}

		const expires = options.expires;
		const expirationDate = new Date(this.today.getTime() + (expires * 24 * 60 * 60 * 1000));

		document.cookie = `${name}=${value};expires=${expirationDate.toUTCString()}`;
	}

	private getCookie(name: string): string | undefined {
		return document.cookie.split(';')
			.reduce((cookies: { [key: string]: string }, cookie: string): { [key: string]: string } => {
				const [name, value] = cookie.split('=');
				cookies[name.trim()] = value;

				return cookies;
			}, {default: ''})[name] || undefined;
	}

	private removeCookie(name: string): void {
		const cookie = this.getCookie(name);

		if (!cookie) {
			return;
		}

		document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
	}
}
