import Main from '@/js/main';
import MainApp from '@/js/main';

export default class ResizeHeadlines {
	element: HTMLElement;
	gridColumnGutter: { tiny: number };
	rootFontSize: number;

	constructor(element: HTMLElement) {
		this.element = element;
		this.gridColumnGutter = {tiny: 40};
		this.rootFontSize = parseFloat(window.getComputedStyle(document.body).fontSize);

		ResizeHeadlines.instances.push(this);
	}

	init(): void {
		let ratio: number = (window.innerWidth - this.gridColumnGutter.tiny) / this.elementWidth;
		ratio = ratio === 1 ? 0.5 : ratio;

		this.element.style.fontSize = `${this.elementFontSize / this.rootFontSize * ratio - 0.05}rem`
	}

	public resizeEvent(): void {
		this.element.removeAttribute('style');

		if (!Main.isDesktop || MainApp.isIpadPro) {
			this.init();
		}
	}

	get elementFontSize(): number {
		return parseFloat(window.getComputedStyle(this.element).fontSize);
	}

	get elementWidth(): number {
		return this.element.offsetWidth;
	}

	static instances: ResizeHeadlines[] = [];
}
