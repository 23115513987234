// @ts-nocheck
function SmoothScroll(target, speed, smooth) {
	if (target === document) {
		this.target = (document.scrollingElement
			|| document.documentElement
			|| document.body.parentNode
			|| document.body) // cross browser support for document scrolling
	} else {
		this.target = target;
	}

	this.pos = target.scrollLeft
	this.moving = false
	this.smooth = smooth;

	// safari is the new IE
	this.frame = target === document.body && document.documentElement ? document.documentElement : this.target

	target.addEventListener('mousewheel', scrolled, {passive: false});
	target.addEventListener('DOMMouseScroll', loaded, {passive: false});

	const $this = this;

	function loaded(e) {
		window.scrollTo(0, 0, true);

		scrolled(e);
	}

	function scrolled(e) {
		if (/iPad/i.test(window.navigator.userAgent)) {
			return;
		}

		e.preventDefault(); // disable default scrolling

		const delta = normalizeWheelDelta(e)

		$this.pos += -delta * speed
		$this.pos = Math.max(0, Math.min($this.pos, $this.target.scrollWidth - $this.frame.clientWidth)) // limit scrolling

		if (!$this.moving) update()
	}

	function normalizeWheelDelta(e) {
		if (e.detail) {
			if (e.wheelDelta) {
				// Opera
				return e.wheelDelta / e.detail / 40 * (e.detail > 0 ? 1 : -1);
			} else {
				// Firefox
				return -e.detail * 0.9;
			}
		} else {
			// IE,Safari,Chrome
			return e.wheelDelta / 120
		}
	}

	function update() {
		$this.moving = true

		const delta = ($this.pos - $this.target.scrollLeft) / $this.smooth

		$this.target.scrollLeft += delta

		if (Math.abs(delta) >= 1) {
			requestAnimationFrame(update);
		} else {
			$this.moving = false;
			window.scrollTo(0, $this.target.scrollLeft, true);
		}
	}
}

export default SmoothScroll;
