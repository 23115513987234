import Section from '@/js/components/canvas/scroll/section';
import Canvas from '@/js/components/canvas/core/canvas';
import Scrollbar from '@/js/components/canvas/scroll/scrollbar';
import TextAnim from '@/js/components/DOM/textAnim';
import SectionLock from '@/js/components/DOM/SectionLock';
import FontsPromise from '@/js/components/canvas/core/fonts';

interface ScrollWrapperProps {
	wrapperSelector: string
	sectionSelector: string
	textSelector: string
	sectionLockSelector: string
}

export default class ScrollWrapper {
	public wrapper: HTMLElement;
	public sections: Section[];
	public texts: TextAnim[];
	public sectionLocks: SectionLock[];
	public lockedSections: Section[] = [];

	public scrollWidth: number = 0;
	public scrollPerc: number = 0;

	public scrollbar: Scrollbar;

	public $props: ScrollWrapperProps;

	constructor(props: ScrollWrapperProps) {
		this.$props = props;

		ScrollWrapper.instance = this;
	}

	public install(props: ScrollWrapperProps = this.$props) {
		this.uninstall(props);

		this.afterLoad();

		for (const section of this.sections) {
			section.getStaticPosition();
		}

		for (const section of this.sections) {
			section.setStaticPosition();
		}

		(window as any).app = this;
	}

	public uninstall(props: ScrollWrapperProps = this.$props) {
		this.wrapper = document.querySelector(props.wrapperSelector) as HTMLElement;

		this.sections = Array.prototype.map
			.call(document.querySelectorAll(props.sectionSelector), (element) => {
				return new Section({element, wrapper: this.wrapper, lock: Boolean(element.dataset.lock)}, element.getAttribute('data-secondary') === "true", element.getAttribute('data-name'));
			}) as Section[];

		this.sections.forEach((section: Section) => {
			if (!section.lock) {
				return;
			}
			this.lockedSections.push(section);
		});

		if (!this.texts) {
			this.texts = Array.prototype.map
				.call(document.querySelectorAll(props.textSelector), (element) => {
					return new TextAnim(element, element.hasAttribute('data-whole'), parseFloat(element.getAttribute('data-delay')) || 0, element.hasAttribute('data-html'));
				}) as TextAnim[];

			this.sectionLocks = Array.prototype.map
				.call(document.querySelectorAll(props.sectionLockSelector), (element) => {
					return new SectionLock(element);
				}) as SectionLock[];
		} else if (this.texts.length > 0) {
			this.texts.forEach((instance) => instance.getStaticPosition());
		}

		this.scrollbar = new Scrollbar();

		for (const section of this.sections) {
			section.uninstall();
		}
	}

	public arrangeSections() {
		const menu = document.querySelector('.header__side')!;
		const secondaryMenuClass = 'header__side--secondary';

		for (const section of this.sections) {
			if (Canvas.inViewport(section.x, section.width)) {
				section.setOffsetPosition();

				if (section.name) {
					const el = document.querySelector(`[data-nav-link="${section.name}"]`);

					if (el) {
						const activeClass = 'nav__item--active';

						if (!el.classList.contains(activeClass)) {
							Array.prototype.forEach.call(document.querySelectorAll('[data-nav-link-menu]'), (link) => {
								link.classList.remove(activeClass);
							});

							el.classList.add(activeClass);
						}
					}
				}
			} else {
				section.setDefaultPosition();
			}
		}

		for (const section of this.sections) {
			if (Canvas.inViewport(section.x + window.innerWidth - 80, section.width)) {
				if (section.isSecondary && !menu.classList.contains(secondaryMenuClass)) {
					menu.classList.add(secondaryMenuClass);
				} else if (menu.classList.contains(secondaryMenuClass)) {
					menu.classList.remove(secondaryMenuClass);
				}
			}
		}
	}

	public refreshSections() {
		const menu = document.querySelector('.header__side')!;
		const secondaryMenuClass = 'header__side--secondary';

		for (const section of this.sections) {
			if (Canvas.inViewport(section.x, section.width)) {
				section.setOffsetPosition();

				if (section.name) {
					const el = document.querySelector(`[data-nav-link="${section.name}"]`);

					if (el) {
						const activeClass = 'nav__item--active';

						if (!el.classList.contains(activeClass)) {
							Array.prototype.forEach.call(document.querySelectorAll('[data-nav-link-menu]'), (link) => {
								link.classList.remove(activeClass);
							});

							el.classList.add(activeClass);
						}
					}
				}
			} else {
				section.setDefaultPosition();
			}
		}

		for (const section of this.sections) {
			if (Canvas.inViewport(section.x + window.innerWidth - 80, section.width)) {
				if (section.isSecondary && !menu.classList.contains(secondaryMenuClass)) {
					menu.classList.add(secondaryMenuClass);
				} else if (menu.classList.contains(secondaryMenuClass)) {
					menu.classList.remove(secondaryMenuClass);
				}
			}
		}
	}

	public intersectionText() {
		for (const text of this.texts) {
			if (Canvas.inViewport(text.x, text.width)) {
				text.animateLettersIn();
			} else {
				text.animateLettersOut();
			}
		}
	}

	public sectionLock() {
		for (const lockedSection of this.lockedSections) {
			if (Canvas.inViewport(lockedSection.x, lockedSection.width)) {
				for (const section of this.sectionLocks) {
					section.lock();
				}
			}
		}
	}

	public updateScrollbar() {
		this.scrollbar.update(this.scrollPerc);
	}

	public afterLoad(): void {
		getComputedStyle(this.wrapper);

		this.scrollWidth = this.wrapper.scrollWidth;

		this.wrapper.style.width = `${this.scrollWidth}px`;
		this.wrapper.style.overflow = 'hidden';

		getComputedStyle(this.wrapper);
	}

	static instance: ScrollWrapper;

	static MAGIC_CONSTANT: number = 0.03;
}
