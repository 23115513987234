import Layer from '@/js/components/canvas/core/layer';
import Mouse from '@/js/components/canvas/core/mouse';
import Ease from '@/js/components/canvas/core/ease';
import Canvas from '@/js/components/canvas/core/canvas';

interface MouseLayerCursorData {
	tick: number
	alpha: number
	scale: number
	r: number
}

interface MouseLayerData {
	tick: number
	cursor: MouseLayerCursorData
}

export default function MouseLayer() {
	const cursor = {
		tick: 0,
		alpha: 1,
		scale: 1,
		r: 25
	};

	new Layer({
		name: 'mouse-cursor',
		width: window.innerWidth,
		height: window.innerHeight,

		data(): MouseLayerData {
			return {tick: 0, cursor};
		},

		draw(ctx, $data: MouseLayerData) {
			const {x, y} = Mouse.get();
			const {isPressed, isHovered, secondary: mouseSecondary} = Mouse.getState();

			if (isHovered) {
				$data.cursor.tick -= 1 / 30;
				$data.cursor.tick = Math.max($data.cursor.tick, 0.3);

				$data.cursor.alpha = Ease.easeInOutQuad($data.cursor.tick);
			} else if ($data.tick < 1) {
				$data.cursor.tick += 1 / 30;
				$data.cursor.tick = Math.min($data.cursor.tick, 1);
			}

			if (isPressed || x < 100) {
				$data.tick -= 1 / 30;
				$data.tick = Math.max($data.tick, Canvas.DragPressedScale);

				$data.cursor.scale = Ease.easeInOutQuad($data.tick);
			} else if ($data.tick < 1) {
				$data.tick += 1 / 30;
				$data.tick = Math.min($data.tick, 1);

				$data.cursor.scale = Ease.easeInOutQuad($data.tick);
			}

			const r = $data.cursor.r;
			const xN = x;
			const yN = y;

			if (!mouseSecondary) {
				ctx.fillStyle = (isHovered || x < 100) ? 'rgba(255,255,255,0)' : '#C4E4F5';
				ctx.strokeStyle = (isHovered || x < 100) ? '#004466' : '#C4E4F5';
			} else {
				ctx.fillStyle = (isHovered || x < 100) ? 'rgba(255,255,255,0)' : '#fff';
				ctx.strokeStyle = (isHovered || x < 100) ? '#004466' : '#fff';
			}

			ctx.beginPath();
			ctx.arc(Math.max(xN, 80), yN, r * ($data.cursor.scale + 0.3), 0, 2 * Math.PI);
			ctx.stroke();
			ctx.fill();

			if (!isHovered && x > 100) {
				ctx.beginPath();
				ctx.strokeStyle = '#000000';
				ctx.moveTo(xN - 8.5, yN - r + 18.5);
				ctx.lineTo(xN - 16.5, yN - r + 26.5);
				ctx.lineTo(xN - 8.5, yN - r + 32.5);
				ctx.stroke();
				ctx.closePath();

				ctx.beginPath();
				ctx.strokeStyle = '#000000';
				ctx.moveTo(xN + 8.5, yN - r + 18.5);
				ctx.lineTo(xN + 16.5, yN - r + 26.5);
				ctx.lineTo(xN + 8.5, yN - r + 32.5);
				ctx.stroke();
				ctx.closePath();
			}
		}
	});
}
