interface CanvasProps {
	name: string
	width?: number
	height?: number
}

interface BlankCanvas {
	domElement: HTMLCanvasElement
	ctx: CanvasRenderingContext2D

	render(offscreenCanvas: HTMLCanvasElement, clearOnly?: boolean): any
}

export {
	CanvasProps,
	BlankCanvas
};

export default class Canvas {
	public canRender: boolean = false;

	public name: string;
	public width: number;
	public height: number;

	constructor(props: CanvasProps) {
		const {name, width, height} = props;

		this.name = name;
		this.width = width || window.innerWidth;
		this.height = height || window.innerHeight;
	}

	static inViewport(x: number, width: number): boolean {
		return window.pageXOffset >= x - window.innerWidth && window.pageXOffset <= x + width;
	}

	static MakeBrowserFriendly(): void {
		return;

		if (typeof window.webkitRequestAnimationFrame !== 'undefined') window.requestAnimationFrame = window.webkitRequestAnimationFrame;
		// @ts-ignore
		if (typeof window.mozRequestAnimationFrame !== 'undefined') window.requestAnimationFrame = window.mozRequestAnimationFrame;
		// @ts-ignore
		if (typeof window.oRequestAnimationFrame !== 'undefined') window.requestAnimationFrame = window.oRequestAnimationFrame;
		// @ts-ignore
		if (typeof window.msRequestAnimationFrame !== 'undefined') window.requestAnimationFrame = window.msRequestAnimationFrame;
	}

	static ModifyScrollToFunction(): void {
		const cache = window.scrollTo;

		// @ts-ignore
		window.scrollTo = (x: number, y: number, force: boolean = false, clicked: boolean = false) => {
			if (!force) {
				const yTo = Math.min(Math.max(y * Canvas.DragForce, Canvas.DragPressedMin), Canvas.DragPressedMax);
				cache(window.pageXOffset - yTo, 0);
			}

			if (clicked) {
				cache(y, 0);
			}

			// @ts-ignore
			window.smooth.pos = window.pageXOffset;
			// @ts-ignore
			window.smooth.target.scrollLeft = window.pageXOffset;
		}
	}

	static MakeBlankCanvas(width: number, height: number, zIndex: number = -1): BlankCanvas {
		const domElement = document.createElement('canvas');
		domElement.id = `${this.name}-${Math.random().toString().split('.')[1]}`;
		domElement.width = width;
		domElement.height = height;

		const ctx = domElement.getContext('2d')!;

		domElement.style.position = 'fixed';
		domElement.style.top = '0';
		domElement.style.left = '0';
		domElement.style.zIndex = `${zIndex}`;
		domElement.style.pointerEvents = 'none';

		const render = (offscreenCanvas: HTMLCanvasElement = domElement, clearOnly: boolean = false) => {
			ctx.save();

			if (clearOnly) {
				ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);

				return;
			}

			ctx.drawImage(offscreenCanvas, 0, 0);
		};

		return {domElement, ctx, render};
	}

	static mount(element: HTMLCanvasElement): void {
		const attributeName = 'data-mounted';

		if (element.getAttribute(attributeName) === 'true' && window.innerWidth < Canvas.MOBILE_WIDTH) {
			element.setAttribute(attributeName, 'false');

			document.body.removeChild(element);

			return;
		}

		element.setAttribute(attributeName, 'true');
		document.body.appendChild(element);
	}

	static isMS(): boolean {
		const ua = navigator.userAgent;

		const isIpad = /iPad/i.test(ua) || /iPhone OS 3_1_2/i.test(ua) || /iPhone OS 3_2_2/i.test(ua);
		const isMS = new RegExp('MSIE |Trident/|Edge/').test(ua);

		return isIpad || isMS;
	}

	static DragForce: number = 0.1;

	static DragPressedScale: number = 0.7;

	static DragPressedMax: number = 80;

	static DragPressedMin: number = -Canvas.DragPressedMax;

	static MOBILE_WIDTH: number = 901;
}
