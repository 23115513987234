export default class SectionLock {

	element: HTMLElement;
	lockedElement: HTMLElement | null;

	x: number;
	width: number;
	offset: number;

	constructor(element: HTMLElement) {
		this.offset = 80;
		this.element = element;
		this.lockedElement = element.querySelector('[data-fixed-element]') || null;
		this.getStaticPosition();
	}

	public getStaticPosition(): void {
		const {x, width} = this.element.getBoundingClientRect();
		this.x = x;
		this.width = width;
	}

	public lock() {
		this.getStaticPosition();

		if (!this.lockedElement) {
			return;
		}

		const offset = Math.abs(this.x - this.offset);

		if (this.x <= this.offset && !(this.width <= this.lockedElement.clientWidth + offset) ) {
			this.lockedElement.style.transform = `translate3d(${offset}px, 0px, 0px)`;
		}

	}

	static inViewport(x: number, width: number): boolean {
		return window.pageXOffset >= x - window.innerWidth && window.pageXOffset <= x + width;
	}

}
