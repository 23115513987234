import '@/scss/common/build_common';

import MainApp from '@/js/main';

import CanvasApp from '@/js/components/canvas/index';
import {InstallFonts} from '@/js/components/canvas/core/fonts.ts';

import Decoration from '@/js/components/DOM/Decoration';

InstallFonts();

MainApp.init();

if (MainApp.isDesktop && !MainApp.isIpadPro) {
	CanvasApp.init();
} else {
	document.documentElement.className = 'fonts-loaded';

	new Decoration(document.querySelector('#about > section.intro > div.intro__bottom > div.intro__img > div.decoration')!, 4, 4, Decoration.INTRO);
}
