import Canvas from '@/js/components/canvas/core/canvas';

export default class Decoration {
	public sizeX: number;
	public sizeY: number;
	public seed: any[];

	public parent: Element;
	public wrapper: SVGSVGElement;
	public left: number;
	public width: number;

	public isVisible: boolean = false;

	constructor(parent: Element, sizeX: number, sizeY: number, seed: any[]) {
		this.parent = parent;
		this.sizeX = sizeX;
		this.sizeY = sizeY;
		this.seed = seed;

		this.wrapper = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		this.wrapper.setAttribute('viewBox', `0 0 ${sizeX * 100} ${sizeY * 100}`);

		this.generate();

		this.parent.innerHTML = '';
		this.parent.appendChild(this.wrapper);

		this.getOffset();

		Decoration.instances.push(this);
	}

	public generate(): void {
		this.wrapper.innerHTML = '';

		for (let x = 0; x < this.sizeX; x++) {
			for (let y = 0; y < this.sizeY; y++) {
				const {type, color} = this.seed.find((i) => i.x === x + 1 && i.y === y + 1);

				const child = document.createElementNS('http://www.w3.org/2000/svg', 'use');

				child.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#svg-circle-${type}`);

				child.setAttribute(`data-svg-circle-${type}`, 'true');
				child.setAttribute('x', `${x * 100}`);
				child.setAttribute('y', `${y * 100}`);

				const delay = `${Math.random()}s`;
				const origin = `${x * 100 + 50}px ${y * 100 + 50}px`;

				const transition = !Canvas.isMS() ? '' : 'transition:none;';

				child.setAttribute('fill', color);
				child.setAttribute('style', transition + `transition-delay:${delay};transform-origin:${origin};`);

				this.wrapper.appendChild(child);
			}
		}
	}

	private getOffset(): void {
		getComputedStyle(this.parent);

		const {width, left} = this.parent.getBoundingClientRect();

		this.left = left;
		this.width = width;
	}

	public animateIn(): void {
		if (this.isVisible) {
			return;
		}

		this.isVisible = true;

		this.parent.classList.add('animated');
	}

	public draw(): void {
		if (Canvas.inViewport(this.left, this.width)) {
			this.animateIn();
		}
	}

	static checkIntersection() {
		Decoration.instances.forEach((instance) => instance.draw());
	}

	static instances: Decoration[] = [];

	// 4x4
	static INTRO: any[] = [
		{
			type: 'bottom-right',
			color: '#66CCFF',
			x: 1,
			y: 1
		}, {
			type: 'top-left',
			color: '#F5F2EB',
			x: 2,
			y: 1
		}, {
			type: 'full',
			color: '#1A1917',
			x: 3,
			y: 1
		}, {
			type: 'top-left',
			color: '#FFB3BF',
			x: 4,
			y: 1
		}, {
			type: 'full',
			color: '#1A1917',
			x: 1,
			y: 2
		}, {
			type: 'bottom-left',
			color: '#C4E4F5',
			x: 2,
			y: 2
		}, {
			type: 'top-right',
			color: '#004466',
			x: 3,
			y: 2
		}, {
			type: 'top-right',
			color: '#D9D6D0',
			x: 4,
			y: 2
		}, {
			type: 'bottom-right',
			color: '#FF6A4D',
			x: 1,
			y: 3
		}, {
			type: 'full',
			color: '#146630',
			x: 2,
			y: 3
		}, {
			type: 'bottom-left',
			color: '#66CCFF',
			x: 3,
			y: 3
		}, {
			type: 'bottom-right',
			color: '#F5F2EB',
			x: 4,
			y: 3
		}, {
			type: 'top-left',
			color: '#F5F2EB',
			x: 1,
			y: 4
		}, {
			type: 'top-right',
			color: '#1A1917',
			x: 2,
			y: 4
		}, {
			type: 'top-left',
			color: '#FFB3BF',
			x: 3,
			y: 4
		}, {
			type: 'top-right',
			color: '#FF6A4D',
			x: 4,
			y: 4
		}
	];

	// 8x4
	static MIL41: any[] = [
		{
			type: 'top-left',
			color: '#66CCFF',
			y: 1,
			x: 1
		}, {
			type: 'full',
			color: '#FF6A4D',
			y: 1,
			x: 2
		}, {
			type: 'top-left',
			color: '#F5F2EB',
			y: 1,
			x: 3
		}, {
			type: 'top-right',
			color: '#D9D6D0',
			y: 1,
			x: 4
		}, {
			type: 'top-right',
			color: '#66CCFF',
			y: 1,
			x: 5
		}, {
			type: 'top-left',
			color: '#FF6A4D',
			y: 1,
			x: 6
		}, {
			type: 'bottom-right',
			color: '#146630',
			y: 1,
			x: 7
		}, {
			type: 'full',
			color: '#F5F2EB',
			y: 1,
			x: 8
		}, {
			type: 'bottom-left',
			color: '#FFB3BF',
			y: 2,
			x: 1
		}, {
			type: 'bottom-right',
			color: '#146630',
			y: 2,
			x: 2
		}, {
			type: 'full',
			color: '#C4E4F5',
			y: 2,
			x: 3
		}, {
			type: 'bottom-left',
			color: '#1A1917',
			y: 2,
			x: 4
		}, {
			type: 'full',
			color: '#D9D6D0',
			y: 2,
			x: 5
		}, {
			type: 'bottom-left',
			color: '#C4E4F5',
			y: 2,
			x: 6
		}, {
			type: 'top-right',
			color: '#1A1917',
			y: 2,
			x: 7
		}, {
			type: 'top-left',
			color: '#D9D6D0',
			y: 2,
			x: 8
		}, {
			type: 'top-left',
			color: '#F5F2EB',
			y: 3,
			x: 1
		}, {
			type: 'bottom-left',
			color: '#C4E4F5',
			y: 3,
			x: 2
		}, {
			type: 'top-right',
			color: '#FFB3BF',
			y: 3,
			x: 3
		}, {
			type: 'top-left',
			color: '#146630',
			y: 3,
			x: 4
		}, {
			type: 'bottom-right',
			color: '#FF6A4D',
			y: 3,
			x: 5
		}, {
			type: 'full',
			color: '#146630',
			y: 3,
			x: 6
		}, {
			type: 'bottom-left',
			color: '#66CCFF',
			y: 3,
			x: 7
		}, {
			type: 'bottom-right',
			color: '#F5F2EB',
			y: 3,
			x: 8
		}, {
			type: 'bottom-right',
			color: '#1A1917',
			y: 4,
			x: 1
		}, {
			type: 'full',
			color: '#D9D6D0',
			y: 4,
			x: 2
		}, {
			type: 'bottom-right',
			color: '#66CCFF',
			y: 4,
			x: 3
		}, {
			type: 'full',
			color: '#C4E4F5',
			y: 4,
			x: 4
		}, {
			type: 'top-left',
			color: '#F5F2EB',
			y: 4,
			x: 5
		}, {
			type: 'top-right',
			color: '#C4E4F5',
			y: 4,
			x: 6
		}, {
			type: 'top-left',
			color: '#FFB3BF',
			y: 4,
			x: 7
		}, {
			type: 'bottom-right',
			color: '#FF6A4D',
			y: 4,
			x: 8
		}
	];

	// 9x4
	static LOANS: any[] = [
		{
			type: 'top-left',
			color: '#66CCFF',
			y: 1,
			x: 1
		}, {
			type: 'full',
			color: '#FF6A4D',
			y: 1,
			x: 2
		}, {
			type: 'top-left',
			color: '#F5F2EB',
			y: 1,
			x: 3
		}, {
			type: 'top-right',
			color: '#D9D6D0',
			y: 1,
			x: 4
		}, {
			type: 'top-right',
			color: '#66CCFF',
			y: 1,
			x: 5
		}, {
			type: 'top-left',
			color: '#FF6A4D',
			y: 1,
			x: 6
		}, {
			type: 'bottom-right',
			color: '#146630',
			y: 1,
			x: 7
		}, {
			type: 'full',
			color: '#F5F2EB',
			y: 1,
			x: 8
		}, {
			type: 'bottom-right',
			color: '#004466',
			y: 1,
			x: 9
		}, {
			type: 'bottom-left',
			color: '#FFB3BF',
			y: 2,
			x: 1
		}, {
			type: 'bottom-right',
			color: '#004466',
			y: 2,
			x: 2
		}, {
			type: 'full',
			color: '#C4E4F5',
			y: 2,
			x: 3
		}, {
			type: 'bottom-left',
			color: '#146630',
			y: 2,
			x: 4
		}, {
			type: 'full',
			color: '#D9D6D0',
			y: 2,
			x: 5
		}, {
			type: 'bottom-left',
			color: '#C4E4F5',
			y: 2,
			x: 6
		}, {
			type: 'top-right',
			color: '#004466',
			y: 2,
			x: 7
		}, {
			type: 'top-left',
			color: '#D9D6D0',
			y: 2,
			x: 8
		}, {
			type: 'full',
			color: '#FFB3BF',
			y: 2,
			x: 9
		}, {
			type: 'top-left',
			color: '#F5F2EB',
			y: 3,
			x: 1
		}, {
			type: 'bottom-left',
			color: '#C4E4F5',
			y: 3,
			x: 2
		}, {
			type: 'top-right',
			color: '#FFB3BF',
			y: 3,
			x: 3
		}, {
			type: 'top-left',
			color: '#004466',
			y: 3,
			x: 4
		}, {
			type: 'bottom-right',
			color: '#FF6A4D',
			y: 3,
			x: 5
		}, {
			type: 'full',
			color: '#146630',
			y: 3,
			x: 6
		}, {
			type: 'bottom-left',
			color: '#66CCFF',
			y: 3,
			x: 7
		}, {
			type: 'bottom-right',
			color: '#F5F2EB',
			y: 3,
			x: 8
		}, {
			type: 'top-right',
			color: '#146630',
			y: 3,
			x: 9
		}, {
			type: 'bottom-right',
			color: '#146630',
			y: 4,
			x: 1
		}, {
			type: 'full',
			color: '#D9D6D0',
			y: 4,
			x: 2
		}, {
			type: 'bottom-right',
			color: '#66CCFF',
			y: 4,
			x: 3
		}, {
			type: 'full',
			color: '#C4E4F5',
			y: 4,
			x: 4
		}, {
			type: 'top-left',
			color: '#F5F2EB',
			y: 4,
			x: 5
		}, {
			type: 'top-right',
			color: '#C4E4F5',
			y: 4,
			x: 6
		}, {
			type: 'top-left',
			color: '#FFB3BF',
			y: 4,
			x: 7
		}, {
			type: 'bottom-right',
			color: '#FF6A4D',
			y: 4,
			x: 8
		}, {
			type: 'bottom-left',
			color: '#C4E4F5',
			y: 4,
			x: 9
		}
	];

	// 9x4
	static CUSTOMERS: any[] = [
		{
			type: 'top-right',
			color: '#146630',
			y: 1,
			x: 1
		}, {
			type: 'full',
			color: '#D9D6D0',
			y: 1,
			x: 2
		}, {
			type: 'top-right',
			color: '#FF6A4D',
			y: 1,
			x: 3
		}, {
			type: 'full',
			color: '#1A1917',
			y: 1,
			x: 4
		}, {
			type: 'bottom-left',
			color: '#F5F2EB',
			y: 1,
			x: 5
		}, {
			type: 'bottom-right',
			color: '#8C887E',
			y: 1,
			x: 6
		}, {
			type: 'bottom-left',
			color: '#FFB3BF',
			y: 1,
			x: 7
		}, {
			type: 'top-right',
			color: '#66CCFF',
			y: 1,
			x: 8
		}, {
			type: 'full',
			color: '#004466',
			y: 1,
			x: 9
		}, {
			type: 'bottom-left',
			color: '#F5F2EB',
			y: 2,
			x: 1
		}, {
			type: 'top-left',
			color: '#8C887E',
			y: 2,
			x: 2
		}, {
			type: 'bottom-right',
			color: '#FFB3BF',
			y: 2,
			x: 3
		}, {
			type: 'bottom-left',
			color: '#66CCFF',
			y: 2,
			x: 4
		}, {
			type: 'top-right',
			color: '#FF6A4D',
			y: 2,
			x: 5
		}, {
			type: 'full',
			color: '#146630',
			y: 2,
			x: 6
		}, {
			type: 'top-left',
			color: '#1A1917',
			y: 2,
			x: 7
		}, {
			type: 'top-right',
			color: '#F5F2EB',
			y: 2,
			x: 8
		}, {
			type: 'top-right',
			color: '#146630',
			y: 2,
			x: 9
		}, {
			type: 'top-left',
			color: '#FFB3BF',
			y: 3,
			x: 1
		}, {
			type: 'top-right',
			color: '#1A1917',
			y: 3,
			x: 2
		}, {
			type: 'full',
			color: '#004466',
			y: 3,
			x: 3
		}, {
			type: 'top-left',
			color: '#D9D6D0',
			y: 3,
			x: 4
		}, {
			type: 'full',
			color: '#FFB3BF',
			y: 3,
			x: 5
		}, {
			type: 'top-left',
			color: '#146630',
			y: 3,
			x: 6
		}, {
			type: 'bottom-right',
			color: '#004466',
			y: 3,
			x: 7
		}, {
			type: 'bottom-left',
			color: '#D9D6D0',
			y: 3,
			x: 8
		}, {
			type: 'bottom-left',
			color: '#1A1917',
			y: 3,
			x: 9
		}, {
			type: 'bottom-left',
			color: '#66CCFF',
			y: 4,
			x: 1
		}, {
			type: 'full',
			color: '#FF6A4D',
			y: 4,
			x: 2
		}, {
			type: 'bottom-left',
			color: '#F5F2EB',
			y: 4,
			x: 3
		}, {
			type: 'bottom-right',
			color: '#D9D6D0',
			y: 4,
			x: 4
		}, {
			type: 'bottom-right',
			color: '#66CCFF',
			y: 4,
			x: 5
		}, {
			type: 'bottom-left',
			color: '#FF6A4D',
			y: 4,
			x: 6
		}, {
			type: 'top-right',
			color: '#146630',
			y: 4,
			x: 7
		}, {
			type: 'full',
			color: '#F5F2EB',
			y: 4,
			x: 8
		}, {
			type: 'top-left',
			color: '#FFB3BF',
			y: 4,
			x: 9
		}
	];

	// 9x3
	static CAREER: any[] = [
		{
			type: 'bottom-left',
			color: '#F5F2EB',
			y: 1,
			x: 1
		}, {
			type: 'top-left',
			color: '#8C887E',
			y: 1,
			x: 2
		}, {
			type: 'bottom-right',
			color: '#FFB3BF',
			y: 1,
			x: 3
		}, {
			type: 'bottom-left',
			color: '#66CCFF',
			y: 1,
			x: 4
		}, {
			type: 'top-right',
			color: '#FF6A4D',
			y: 1,
			x: 5
		}, {
			type: 'full',
			color: '#146630',
			y: 1,
			x: 6
		}, {
			type: 'top-left',
			color: '#1A1917',
			y: 1,
			x: 7
		}, {
			type: 'top-right',
			color: '#F5F2EB',
			y: 1,
			x: 8
		}, {
			type: 'top-right',
			color: '#146630',
			y: 1,
			x: 9
		}, {
			type: 'top-left',
			color: '#FFB3BF',
			y: 2,
			x: 1
		}, {
			type: 'top-right',
			color: '#1A1917',
			y: 2,
			x: 2
		}, {
			type: 'full',
			color: '#004466',
			y: 2,
			x: 3
		}, {
			type: 'top-left',
			color: '#D9D6D0',
			y: 2,
			x: 4
		}, {
			type: 'full',
			color: '#FFB3BF',
			y: 2,
			x: 5
		}, {
			type: 'top-left',
			color: '#146630',
			y: 2,
			x: 6
		}, {
			type: 'bottom-right',
			color: '#004466',
			y: 2,
			x: 7
		}, {
			type: 'bottom-left',
			color: '#D9D6D0',
			y: 2,
			x: 8
		}, {
			type: 'bottom-left',
			color: '#1A1917',
			y: 2,
			x: 9
		}, {
			type: 'bottom-left',
			color: '#66CCFF',
			y: 3,
			x: 1
		}, {
			type: 'full',
			color: '#FF6A4D',
			y: 3,
			x: 2
		}, {
			type: 'bottom-left',
			color: '#F5F2EB',
			y: 3,
			x: 3
		}, {
			type: 'bottom-right',
			color: '#D9D6D0',
			y: 3,
			x: 4
		}, {
			type: 'bottom-right',
			color: '#66CCFF',
			y: 3,
			x: 5
		}, {
			type: 'bottom-left',
			color: '#FF6A4D',
			y: 3,
			x: 6
		}, {
			type: 'top-right',
			color: '#146630',
			y: 3,
			x: 7
		}, {
			type: 'full',
			color: '#F5F2EB',
			y: 3,
			x: 8
		}, {
			type: 'top-left',
			color: '#FFB3BF',
			y: 3,
			x: 9
		}
	]
}
