import Canvas, {CanvasProps} from '@/js/components/canvas/core/canvas';

import Mouse from '@/js/components/canvas/core/mouse';
import Ease from '@/js/components/canvas/core/ease';

type ImagePropsType = null | 'secondary';

interface ImageProps extends CanvasProps {
	type?: ImagePropsType
	src: string
	target: string
	// text: string[]
}

export {
	ImageProps
};

export default class ImageChild extends Canvas {
	public src: string;
	public target: HTMLElement;
	// public text: ImageText;

	public left: number = 0;
	public width: number = 0;
	public height: number = 0;
	public imageInstance: HTMLImageElement;
	public power: number = Math.random() * 2;
	public type: ImagePropsType = null;

	private props: ImageProps;
	private index: number;

	private revealed: number = 0;

	get isSecondary(): boolean {
		return this.type === 'secondary';
	}

	constructor(props: ImageProps) {
		super(props);

		this.props = props;
		this.type = props.type ? props.type : null;

		this.src = props.src;
		this.target = document.querySelector(props.target)! as HTMLElement;

		ImageChild.instances.push(this);

		this.index = (ImageChild.instances.length - 1) * 0.8;
	}

	public async load(): Promise<HTMLImageElement> {
		return new Promise((resolve, reject) => {
			const image = new Image();

			image.src = `${this.getReplacedPath()}/${this.src}`;

			image.onload = () => {
				this.getStaticPosition();

				this.imageInstance = image;

				/* this.text = new ImageText({
					name: `text-${this.props.name}`,
					width: this.props.width,
					height: this.props.height,
					text: this.props.text,
					left
				}); */

				console.log('image loaded', image);
				resolve(image);
			};

			image.onerror = (error) => {
				reject(error);
			};
		});
	}

	getReplacedPath() {
		const url = window.location.href;
		//@ts-ignore
		const lang = window.language;


		if(lang !== 'cs') {
			return url.replace(lang + '/', '');
		} else {
			if(process.env.NODE_ENV === 'production') {
				if(process.env.LANG === 'cs') {
					return ".";
				} else {
					return ".."
				}
			} else {
				if(lang === 'cs') {
					return `${process.env.ASSET_BASE}`;
				} else {
					return ".."
				}
			}
		}
	}

	public getStaticPosition(): void {
		const {left, width, height} = this.target.getBoundingClientRect();

		this.left = left + (this.isSecondary ? -width : 0);

		if (this.isSecondary) {
			this.width = width * 2;
			this.height = height;

			return;
		}

		if (width / height > 1.8) {
			this.width = width;
			this.height = width / 1.8;
		} else {
			this.width = width;
			this.height = height;
		}
	}

	public draw(ctx: CanvasRenderingContext2D): void {
		if (!this.imageInstance) {
			return;
		}

		this.canRender = Canvas.inViewport(this.left, this.width);

		if (this.canRender) {
			const target = (window as any).smooth.target;

			const x = this.left - target.scrollLeft;
			const y = 0;

			const isSecondary = this.type === 'secondary';
			const power = isSecondary ? 50 : 200;

			const parallaxScale = (window.innerWidth / this.width) * power;
			const parallaxOffset = ((target.scrollLeft + x) / window.innerWidth) + ((x / window.innerWidth - 0.5) * parallaxScale) - (parallaxScale / 2);

			const sizeScale = window.innerWidth / (this.width + power * 2 + parallaxScale);

			const width = this.width * Math.max(sizeScale, 1.2);
			const height = this.height * Math.max(sizeScale, 1.2);

			const yOffset = (window.innerHeight - height) / 2;

			ctx.drawImage(this.imageInstance, x + (parallaxOffset / 2), yOffset, width, height)
		}
	}

	static instances: ImageChild[] = [];
}
