import Canvas, {CanvasProps} from '@/js/components/canvas/core/canvas';

interface LayerProps extends CanvasProps {
	data: () => { [key: string]: any }
	draw: (ctx: CanvasRenderingContext2D, $data: any) => void
}

export default class Layer extends Canvas {
	public draw: (ctx: CanvasRenderingContext2D, $data: any) => void;
	public data: any;

	constructor(props: LayerProps) {
		super({...props});

		this.draw = props.draw;
		this.data = props.data();

		Layer.instances.push(this);
	}

	public render(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
		this.draw(ctx, this.data);
	}

	static instances: Layer[] = [];

	static get(name: string): Layer | undefined {
		return Layer.instances.find((instance) => instance.name === name);
	}
}
