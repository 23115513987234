import Layer from '@/js/components/canvas/core/layer';
import Canvas from '@/js/components/canvas/core/canvas';
import Mouse from '@/js/components/canvas/core/mouse';

interface LoaderLayerData {
	tick: number
	done: () => any
}

export default function LoaderLayer() {
	const done = () => {
		const loader = document.querySelector('.loader')! as HTMLElement;

		loader.style.display = 'none';
	};

	new Layer({
		name: 'loader',
		width: window.innerWidth,
		height: window.innerHeight,

		data(): LoaderLayerData {
			return {tick: 0, done};
		},

		draw(ctx, $data: LoaderLayerData) {
			if ($data.tick < 1) {
				$data.tick += 0.01;

				ctx.globalAlpha = Math.min(Math.max(0, 1 - $data.tick), 1);
				ctx.fillStyle = '#fff';
				ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);
				ctx.globalAlpha = 1;
			}
		}
	});
}
