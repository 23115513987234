import Mouse from '@/js/components/canvas/core/mouse';
import Layer from '@/js/components/canvas/core/layer';
import Canvas from '@/js/components/canvas/core/canvas';

export default function mouseup() {
	Mouse.setState('isPressed', false);

	const mouseLayer = Layer.get('mouse-cursor');

	if (mouseLayer) {
		mouseLayer.data.tick = Canvas.DragPressedScale;
	}
}
