import Canvas from '@/js/components/canvas/core/canvas';

interface SectionProps {
	wrapper: HTMLElement
	element: HTMLElement
	lock: boolean
}

export default class ScrollSection {
	public wrapper: HTMLElement;
	public element: HTMLElement;

	public lock: boolean;
	public x: number = 0;
	public width: number = 0;

	public isSecondary: boolean = false;
	public name: string | undefined = undefined;

	constructor(props: SectionProps, isSecondary: boolean = false, name: string | undefined = undefined) {
		this.wrapper = props.wrapper;
		this.element = props.element;
		this.lock = props.lock || false;

		this.isSecondary = isSecondary
		this.name = name;
	}

	public getStaticPosition(wrapper: HTMLElement = this.wrapper): void {
		const offset = wrapper.scrollLeft;
		const {left, width} = this.element.getBoundingClientRect();

		this.x = left + offset;
		this.width = width;
	}

	public setStaticPosition(): void {
		this.element.style.position = 'fixed';
		this.element.style.width = `${this.width}px`;
		this.element.style.height = '100%';
		this.element.style.top = '0';
		this.element.style.left = '0';

		const x = Canvas.isMS() ? Math.floor(this.x) : this.x;

		this.element.style.transform = `translate3d(${x}px, 0, 0)`;
	}

	public setOffsetPosition(): void {
		if (this.element.style.visibility !== 'visible') {
			this.element.style.visibility = 'visible';
		}

		let x = this.x - window.pageXOffset;
		x = Canvas.isMS() ? Math.floor(x) : x;

		this.element.style.transform = `translate3d(${x}px, 0, 0)`;
	}

	public setDefaultPosition(): void {
		if (this.element.style.visibility !== 'hidden') {
			this.element.style.visibility = 'hidden';
		}
	}

	public uninstall(): void {
		this.element.removeAttribute('style');

		delete this.element.style.top;
		delete this.element.style.left;
		delete this.element.style.transform;
	}
}
