import Canvas from '@/js/components/canvas/core/canvas';
import Layer from '@/js/components/canvas/core/layer';
import Mouse from '@/js/components/canvas/core/mouse';

interface WobbleLayerImageData {
	instance: HTMLImageElement
	bounds: {
		top: number
		left: number
		width: number
		height: number
	}
	animationProgress: number
}

interface WobbleLayerData {
	images: WobbleLayerImageData[]
}

export default function WobbleLayer() {
	const images = Array.prototype.map.call(document.querySelectorAll('[data-team-image]'), (el: HTMLImageElement) => {
		const {top, left, width, height} = el.getBoundingClientRect();

		return {
			bounds: {
				top: Math.round(top),
				left: Math.round(left),
				width: Math.round(width),
				height: Math.round(height)
			},
			instance: el,
			animationProgress: 0
		};
	}) as WobbleLayerImageData[];

	new Layer({
		name: 'wobble-images',
		width: window.innerWidth,
		height: window.innerHeight,

		data(): WobbleLayerData {
			return {images};
		},

		draw(ctx, $data: WobbleLayerData) {
			const {images} = $data;

			for (const image of images) {
				const {left, top, width, height} = image.bounds;

				if (Canvas.inViewport(left, width)) {
					if (image.animationProgress < 1) {
						image.animationProgress += 1 / 30;
					}

					const {x, y} = Mouse.get();

					const leftOffset = (x / window.innerWidth) * -10 + 5;
					const offsetTop = -1 * ((left - window.pageXOffset) / window.innerWidth) * -150 + (y / window.innerHeight) * -10 - 50;

					ctx.globalAlpha = image.animationProgress;
					ctx.drawImage(image.instance, left - window.pageXOffset + leftOffset, top + offsetTop, width, height);
				}
			}

			ctx.globalAlpha = 1;
		}
	});
};
