export default class LangSwitcher {

	element: HTMLElement | null;
	options: HTMLElement[];
	toggled: boolean = false;
	lang: string;

	constructor(element: HTMLElement | null) {
		this.element = element;
		this.options = [].slice.call(this.element!.children);
		this.lang = (window as any).language;

		this.setClickEvent();
		this.setOptions();
	}

	setClickEvent() {
		this.element!.addEventListener('click', () => {
			this.toggled ? this.element!.classList.remove("toggled") : this.element!.classList.add("toggled");
			this.toggled = !this.toggled;
		}, false);

	}

	setOptions() {
		let currentUrl = window.location.href;

		if(this.lang !== 'en') {
			currentUrl = currentUrl.replace(this.lang + '/', '');
		}

		console.log(currentUrl, this.lang);

		this.options.forEach((option: HTMLElement) => {
			if(option.dataset.lang === this.lang) {
				option.classList.add('active');
			}

			let href = option.dataset.lang === 'en' ? currentUrl : currentUrl + option.dataset.lang;

			option.setAttribute('href', href);

			option.addEventListener('click', (e) => {
				if(option.dataset.lang === this.lang) {
					e.preventDefault();
				}
			})
		})
	}
}
