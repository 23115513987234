import Mouse from '@/js/components/canvas/core/mouse';

export default function mousemove (event: MouseEvent | TouchEvent) {
	event.preventDefault();

	const x = typeof (event as MouseEvent).clientX !== 'undefined' ?
		(event as MouseEvent).clientX : (event as TouchEvent).touches[0].clientX;

	const y = typeof (event as MouseEvent).clientY !== 'undefined' ?
		(event as MouseEvent).clientY : (event as TouchEvent).touches[0].clientY;

	Mouse.set(x, y);
	Mouse.setState('secondary', false);

	// @ts-ignore
	const path = event.path;
	if (path) {
		const section = path.find((el: HTMLElement) => el.nodeName && el.nodeName.toLowerCase() === 'section');

		if (section && section.getAttribute('data-secondary') === 'true') {
			Mouse.setState('secondary', true);
		}
	}
}
