import ScrollWrapper from '@/js/components/canvas/scroll/wrapper';

export default class ScrollToSection {
	static scrollTo(target: string) {
		let x = 0;

		switch (target) {
			case 'about':
				x = ScrollWrapper.instance.sections[1].x;
				break;
			case 'projects':
				x = ScrollWrapper.instance.sections[9].x;
				break;
			case 'team':
				x = ScrollWrapper.instance.sections[13].x;
				break;
			case 'contact':
				x = ScrollWrapper.instance.sections[15].x;
				break;
			case 'britain':
				x = ScrollWrapper.instance.sections[17].x;
				break;
			case 'czech':
				x = ScrollWrapper.instance.sections[18].x;
				break;
			case 'denmark':
				x = ScrollWrapper.instance.sections[19].x;
				break;
			case 'iceland':
				x = ScrollWrapper.instance.sections[20].x;
				break;
		}

		// @ts-ignore
		window.scrollTo(0, x - 80, true, true);
	}
}
