export default class Scrollbar {
	indicator: HTMLElement;

	constructor() {
		this.indicator = document.createElement('div');

		this.indicator.style.background = '#191917';
		this.indicator.style.position = 'fixed';
		this.indicator.style.top = '0';
		this.indicator.style.left = '0';
		this.indicator.style.zIndex = '11';
		this.indicator.style.width = '2px';
		this.indicator.style.height = '100%';
		this.indicator.style.transformOrigin = '50% 0';
		this.indicator.style.transform = 'scale(1, 0)';

		document.body.appendChild(this.indicator);
	}

	public update(progress: number): void {
		this.indicator.style.transform = `scale(1, ${progress})`;
	}
}
