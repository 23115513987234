const {pow} = Math;

export default class Ease {
	static easeInOutQuad(x: number): number {
		return x < 0.5 ? 2 * x * x : 1 - pow(-2 * x + 2, 2) / 2;
	}

	static easeOutQuad(x: number): number {
		return 1 - (1 - x) * (1 - x);
	}

	static easeInOutQuint(x: number): number {
		return x < 0.5 ? 16 * x * x * x * x * x : 1 - pow(-2 * x + 2, 5) / 2;
	}
}

(window as any).Ease = Ease;
