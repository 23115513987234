interface MouseState {
	isHovered: boolean
	isPressed: boolean
	isDragEnd: boolean
	startX: number
	startY: number
	positionDiff: number
	secondary: boolean
}

interface MousePosition {
	x: number
	y: number
}

export default class Mouse {
	static x: number = -100;
	static y: number = -100;

	static state: MouseState = {
		isHovered: false,
		isPressed: false,
		isDragEnd: true,
		startX: 0,
		startY: 0,
		positionDiff: 0,
		secondary: false
	};

	static set(x: number, y: number): void {
		Mouse.x = x;
		Mouse.y = y;
	}

	static get(): MousePosition {
		return {
			x: Mouse.x,
			y: Mouse.y
		};
	}

	static setState(name: string, value: any): void {
		// @ts-ignore
		Mouse.state[name] = value;
	}

	static getState(): MouseState {
		return {...Mouse.state};
	}

	static inIntersection(x: number, y: number, width: number, height: number): boolean {
		return Mouse.x >= (x - window.pageXOffset)
			&& Mouse.x <= (x - window.pageXOffset + width)
			&& Mouse.y >= y
			&& Mouse.y <= y + height;
	}
}
