import ResizeHeadlines from '@/js/components/resizeHeadlines';

export default class Navigation {
	element: HTMLElement = document.getElementById('hamburger') as HTMLElement;
	navigation: HTMLElement;
	financialReports: HTMLElement;
	forPress: HTMLElement;
	financialReportTriggers: NodeListOf<HTMLElement>;
	forPressTriggers: NodeListOf<HTMLElement>;
	state: 'closed' | 'navigation' | 'financial-report' | 'outside-financial-report' | 'for-press' | 'outside-for-press';
	financialDataAttribute: string;
	hamburgerText: HTMLElement;
	logo: HTMLElement;

	constructor() {

		//check if there is hamburger (navigation trigger) and then fill the variables
		if (!this.element) return;

		//two main parts to toggle
		this.navigation = document.getElementById('navigation') as HTMLElement;
		this.financialReports = document.getElementById('financial-reports') as HTMLElement;
		this.forPress = document.getElementById('for-press') as HTMLElement;

		//hamburger text element
		this.hamburgerText = document.getElementById('hamburger-text') as HTMLElement;

		//logo element
		this.logo = document.getElementById('logo') as HTMLElement;

		//nodelist of all financial reports triggers
		this.financialReportTriggers = document.querySelectorAll(`[data-financial-report]`);
		this.forPressTriggers = document.querySelectorAll(`[data-for-press]`);

		//initial state of navigation
		this.state = 'closed';

		this.init();
	}

	init(): void {

		this.element.addEventListener('click', () => {

			//check state of navigation (closed, opened navigation, opened financial report from navigation, opened financial report from outside of header (navigation))
			switch(this.state) {
				case 'closed':
					this.openNavigation();
					break;
				case 'navigation':
					this.closeNavigation();
					break;
				case 'financial-report':
					this.closeFinancialReports();
					this.openNavigation();
					break;
				case 'for-press':
					this.closeForPress();
					this.openNavigation();
					break;
				case 'outside-financial-report':
					this.closeFinancialReports();
					break;
				case 'outside-for-press':
					this.closeForPress();
					break;
			}

			this.toggleHamburger();
		});

		//for each financial report trigger open the list of reports and set state to outside-financial-report if opened from outside of header (navigation)
		Array.prototype.forEach.call(this.financialReportTriggers, (trigger) => {
			trigger.addEventListener('click', () => {
				this.openFinancialReports();
				trigger.getAttribute('data-financial-report') === 'outside' ? this.state = 'outside-financial-report' : null;

				this.toggleHamburger();
			})
		})

		Array.prototype.forEach.call(this.forPressTriggers, (trigger) => {
			trigger.addEventListener('click', () => {
				this.openForPress();
				trigger.getAttribute('data-for-press') === 'outside' ? this.state = 'outside-for-press' : null;

				this.toggleHamburger();
			})
		})
	}

	toggleHamburger(): void {
		switch(this.state) {
			case 'closed':
				this.element.classList.remove('hamburger--navigation', 'hamburger--financial');
				break;
			case 'navigation':
				//@ts-ignore
				this.hamburgerText.textContent = window.language === 'cs' ? 'zavřít' : 'close';
				this.element.classList.remove('hamburger--financial');
				this.element.classList.add('hamburger--navigation');
				break;
			case 'financial-report':
			case 'for-press':
				//@ts-ignore
				this.hamburgerText.textContent = window.language === 'cs' ? 'zpět' : 'back';
				this.element.classList.add('hamburger--financial');
				break;
			case 'outside-financial-report':
			case 'outside-for-press':
				//@ts-ignore
				this.hamburgerText.textContent = window.language === 'cs' ? 'zavřít' : 'close';
				this.element.classList.add('hamburger--navigation');
				break;
		}
	}

	openNavigation(): void {
		this.logo.classList.add('logo--active');
		this.navigation.classList.add('header__main--active');
		this.state = 'navigation';
	}

	openFinancialReports(): void {
		this.logo.classList.remove('logo--active');
		this.financialReports.classList.add('header__financial--active');
		this.state = 'financial-report';
	}

	openForPress(): void {
		this.logo.classList.remove('logo--active');
		this.forPress.classList.add('header__financial--active');
		this.state = 'for-press';
	}

	closeNavigation(): void {
		this.logo.classList.remove('logo--active');
		this.navigation.classList.remove('header__main--active');
		this.state = 'closed';
	}

	closeFinancialReports(): void {
		this.financialReports.classList.remove('header__financial--active');
		this.state = 'closed';
	}

	closeForPress(): void {
		this.forPress.classList.remove('header__financial--active');
		this.state = 'closed';
	}
}
