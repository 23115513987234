import Canvas from '@/js/components/canvas/core/canvas';
import ImageChild, {ImageProps} from '@/js/components/canvas/image/child';

type ImageWrapperProps = ImageChild[];

export default class ImageWrapper {
	children: ImageWrapperProps = [];

	constructor(props: ImageWrapperProps) {
		for (const children of props) {
			this.children.push(children);
		}

		(window as any).appWrapper = this;

		ImageWrapper.instance = this;
	}

	public render(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
		const shouldRender = this.children.some((child) => Canvas.inViewport(child.left, child.width));

		if (!shouldRender) {
			return;
		}

		ctx.fillStyle = '#fff';
		ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);

		for (const children of this.children) {
			children.draw(ctx);
		}
	}

	static MakeImageInstances(data: ImageProps[]): ImageChild[] {
		return data.map((options) => new ImageChild(options));
	}

	static instance: ImageWrapper;
}
