import './polyfills';

import CanvasApp from '@/js/components/canvas';
import debounce from '@/js/utils/debounce';

import Navigation from '@/js/components/navigation';
import ResizeHeadlines from '@/js/components/resizeHeadlines';
import Objectfit from '@/js/utils/objectfit';
import ScrollToSection from '@/js/components/DOM/ScrollTo';
import ScrollWrapper from '@/js/components/canvas/scroll/wrapper';
import Canvas from '@/js/components/canvas/core/canvas';
import textAnim from '@/js/components/DOM/textAnim';
import Cookies from '@/js/components/DOM/Cookies';
import LangSwitcher from '@/js/components/DOM/LangSwitcher';

const ACTIVE_CLASSNAME = 'main__inner--active';
const LINK_CLASSNAME = 'nav__item--active';

const removeActiveLinks = (all: HTMLElement[]) => all.forEach((el: HTMLElement) => {
	el.classList.remove(ACTIVE_CLASSNAME);
	el.classList.remove(LINK_CLASSNAME);
});

export default class MainApp {
	static texts: textAnim[] = [];

	static init() {
		const cookies = new Cookies(MainApp.isDesktop);

		const nav = new Navigation();

		const langSwitcher = new LangSwitcher(document.querySelector("#lang-switcher"));

		const fullWidthHeadlines: NodeListOf<HTMLElement> = document.querySelectorAll('[data-full-width-headline]');

		Array.prototype.forEach.call(fullWidthHeadlines, (element) => {
			if (element.hasAttribute('data-full-width-headline-mobile')) {
				new ResizeHeadlines(element);
			}
		});

		const resizeHeadlinesEvent = () => {
			for (const instance of ResizeHeadlines.instances) {
				instance.resizeEvent();
			}
		};

		window.addEventListener('resize', debounce(resizeHeadlinesEvent, 100));

		const PictureControl_OBJECTFIT_SUPPORT = 'objectFit' in document.documentElement.style;

		if (!PictureControl_OBJECTFIT_SUPPORT) {
			Array.prototype.forEach.call(document.querySelectorAll('[data-objectfit]'), (element) => {
				new Objectfit(element);
			});
		}

		Array.from(document.querySelectorAll('.nav__item')).forEach((item) => {
			item.addEventListener('click', () => {
				nav.closeNavigation();
				nav.toggleHamburger();
			});
		});

		Array.prototype.forEach.call(document.querySelectorAll('[data-nav-link]'), (scrollToButton: HTMLElement) => {
			scrollToButton.addEventListener('click', (e: Event) => {
				e.preventDefault();
				let target = scrollToButton.dataset.navLink

				if (!target) {
					return;
				}

				const about = document.getElementById('about')!;
				const aboutLinks = Array.prototype.map.call(document.querySelectorAll('[data-nav-link=about]'), (i) => i) as HTMLElement[];

				const projects = document.getElementById('projects')!;
				const projectsLinks = Array.prototype.map.call(document.querySelectorAll('[data-nav-link=projects]'), (i) => i) as HTMLElement[];

				const team = document.getElementById('team')!;
				const teamLinks = Array.prototype.map.call(document.querySelectorAll('[data-nav-link=team]'), (i) => i) as HTMLElement[];

				const contact = document.getElementById('contact')!;
				const contactLinks = Array.prototype.map.call(document.querySelectorAll('[data-nav-link=contact]'), (i) => i) as HTMLElement[];

				const all = Array.prototype.map.call(document.querySelectorAll('.main__inner, [data-nav-link]'), (i) => i) as HTMLElement[];

				switch (target) {
					case 'about':
						removeActiveLinks(all);
						about.classList.add(ACTIVE_CLASSNAME);
						aboutLinks.forEach((e) => {
							e.classList.add(LINK_CLASSNAME);
						});
						break;
					case 'projects':
						removeActiveLinks(all);
						projects.classList.add(ACTIVE_CLASSNAME);
						projectsLinks.forEach((e) => {
							e.classList.add(LINK_CLASSNAME);
						});
						break;
					case 'team':
						removeActiveLinks(all);
						team.classList.add(ACTIVE_CLASSNAME);
						teamLinks.forEach((e) => {
							e.classList.add(LINK_CLASSNAME);
						});
						break;
					case 'contact':
						removeActiveLinks(all);
						contact.classList.add(ACTIVE_CLASSNAME);
						contactLinks.forEach((e) => {
							e.classList.add(LINK_CLASSNAME);
						});
						break;
				}

				if (
					window.innerWidth < Canvas.MOBILE_WIDTH
					&& ['about', 'projects', 'team', 'contact'].indexOf(target) > -1
				) {
					window.scrollTo(0, 0);
				}

				if (
					window.innerWidth < Canvas.MOBILE_WIDTH
					&& ['britain', 'czech', 'denmark', 'iceland'].indexOf(target) > -1
				) {
					const el = document.querySelector(`#${target}`);

					if (!el) {
						return;
					}

					window.scrollTo(0, window.pageYOffset + el.getBoundingClientRect().y - 70);
				}

				resizeHeadlinesEvent();

				setTimeout(() => {
					for (const text of MainApp.texts) {
						text.getStaticPosition();
					}

					scrollEvent();
				}, 500);
			});
		}, false);

		CanvasApp.afterLoadAnimation();

		Array.prototype.forEach.call(document.querySelectorAll('[data-full-width-headline]'), (el) => {
			const text = new textAnim(el as HTMLElement, el.hasAttribute('data-whole'), parseFloat(el.getAttribute('data-delay')) || 0, el.hasAttribute('data-html'));
			text.getStaticPosition();

			MainApp.texts.push(text);
		});

		resizeHeadlinesEvent();

		setTimeout(() => {
			for (const text of MainApp.texts) {
				text.getStaticPosition();
			}
		}, 500);

		const scrollEvent = () => {
			const offset = window.pageYOffset;

			for (const text of MainApp.texts) {
				if (offset + window.innerHeight >= text.x) {
					text.animateLettersIn();
				} else {
					text.animateLettersOut();
				}
			}
		};

		scrollEvent();

		window.addEventListener('scroll', scrollEvent.bind(MainApp));
	}

	static breakpoint: number = 1024;

	static get isDesktop(): boolean {
		return window.innerWidth > this.breakpoint;
	}

	static get isIpadPro(): boolean {
		return 'MacIntel' === navigator.platform && 1 < navigator.maxTouchPoints;
	}
}
