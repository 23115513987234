import Canvas from '@/js/components/canvas/core/canvas';

export default class TextAnim {

	originalElement: HTMLElement;
	editedElement: string;

	element: HTMLElement;
	letters: any;
	spans: HTMLElement[];

	instances: any;
	x: number;
	width: number;

	animated: boolean = false;
	delay: number = 0;

	constructor(element: HTMLElement, whole: boolean = false, delay: number = 0, insertHTML?: boolean) {
		this.originalElement = element;
		this.editedElement = '';
		this.element = element;
		this.letters = whole ? [] : Array.from(element.innerText);
		this.delay = 0;

		this.getStaticPosition();

		if (this.element.hasAttribute('data-block')) {
			return;
		}

		if (whole) {
			if (insertHTML) {
				this.element.innerHTML = `<span>${element.innerHTML}</span>`;
			} else {
				this.element.innerHTML = `<span>${element.innerText}</span>`;
			}

			return;
		}

		this.splitToLetter();
	}

	public getStaticPosition(): void {
		const {left, top, width} = this.element.getBoundingClientRect();

		this.x = window.innerWidth < Canvas.MOBILE_WIDTH ? top : left;
		this.width = width;

		this.animated = false;
	}

	splitToLetter() {
		this.letters.forEach((letter: string) => {
			if (letter === ' ') {
				letter = '&nbsp;';
			}
			this.editedElement += `<span>${letter}</span>`
		});

		this.element.innerHTML = this.editedElement;
		this.spans = Array.prototype.map.call(this.element.querySelectorAll('span') || [], (item) => item) as HTMLElement[];
	}

	animateLettersIn(): void {
		if (this.animated) {
			return;
		}

		this.animated = true;

		setTimeout(() => {
			this.element.classList.remove('default');
			this.element.classList.add('animate');
		}, this.delay * 1000);
	}

	animateLettersOut(): void {
		return;

		if (!this.animated) {
			return;
		}

		this.animated = false;

		setTimeout(() => {
			this.element.classList.remove('animate');
			this.element.classList.add('default');
		}, this.delay * 1000);
	}
}
