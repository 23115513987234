import FontFaceObserver from 'fontfaceobserver-es';

const LOADED_CLASSNAME = 'fonts-loaded';

const InstallFonts = () => {
	const link = document.createElement('link');

	link.rel = 'stylesheet';
	link.href = 'https://use.typekit.net/woq7jsa.css';

	document.head.appendChild(link);
}

export default function FontsPromise() {
	return new Promise(async (resolve) => {
		const fonts = [
			{name: 'sofia-pro'},
			{name: 'bebas-neue-by-fontfabric'},
			{name: 'louvette-banner'}
		].map((family) => {
			return new FontFaceObserver(family.name);
		});

		for (const family of fonts) {
			console.log(await family.load('BESbswy', 60000));
		}

		document.documentElement.className = LOADED_CLASSNAME;

		resolve(true);
	})
};

export {
	LOADED_CLASSNAME,
	InstallFonts
};
