import mousedown from '@/js/components/canvas/events/mousedown';
import mouseup from '@/js/components/canvas/events/mouseup';
import mousemove from '@/js/components/canvas/events/mousemove';

import SmoothScrollPolyfill from '@/js/components/canvas/scroll/smooth';
import ScrollWrapper from '@/js/components/canvas/scroll/wrapper';

import ScrollToSection from '@/js/components/DOM/ScrollTo';
import Decoration from '@/js/components/DOM/Decoration';

import FontsPromise from '@/js/components/canvas/core/fonts.ts';
import Canvas from '@/js/components/canvas/core/canvas';
import Layer from '@/js/components/canvas/core/layer';
import Mouse from '@/js/components/canvas/core/mouse';
import ImageWrapper from '@/js/components/canvas/image/wrapper';

import WobbleLayer from '@/js/components/canvas/layers/wobble';
import MouseLayer from '@/js/components/canvas/layers/mouse';
import LoaderLayer from '@/js/components/canvas/layers/loader';
import debounce from '@/js/utils/debounce';

export default class CanvasApp {
	static init() {
		if (Canvas.isMS()) {
			document.body.className = 'is-ms';
		}

		/**
		 * Custom Page scrolling
		 */

		if (Canvas.isMS()) {
			// @ts-ignore
			window.smooth = new SmoothScrollPolyfill(document, 128, 16);
		} else {
			// @ts-ignore
			window.smooth = new SmoothScrollPolyfill(document, 64, 8);
		}

		const scrollWrapper = new ScrollWrapper({
			wrapperSelector: 'main',
			sectionSelector: 'section',
			textSelector: '[data-full-width-headline]',
			sectionLockSelector: '[data-fixed]'
		});

		/**
		 * Decide which render loop to use in different browsers
		 * */

		Canvas.MakeBrowserFriendly();

		Canvas.ModifyScrollToFunction();

		/**
		 * Imaginary canvas, used for pre-renders and then placed on top of main canvas
		 */

		const {
			domElement: offscreenCanvas,
			ctx: offscreenCtx
		} = Canvas.MakeBlankCanvas(window.innerWidth, window.innerHeight);

		/**
		 * This is the canvas that all stuff will be drawn upon
		 */

		const {domElement, render} = Canvas.MakeBlankCanvas(window.innerWidth, window.innerHeight);
		const {domElement: domElementLayers, render: renderLayers, ctx: layerCtx} = Canvas.MakeBlankCanvas(window.innerWidth, window.innerHeight, 10);

		/**
		 * Main render function
		 */

		const mainWrappger: HTMLElement | null = document.querySelector('#main');

		const canvasAnimation = () => {
			if (window.innerWidth < Canvas.MOBILE_WIDTH) {
				requestAnimationFrame(canvasAnimation);

				return;
			}

			const {x} = Mouse.get();
			const {isPressed, isDragEnd, startX, positionDiff} = Mouse.getState();

			if (mainWrappger) {
				mainWrappger.style.cursor = isPressed ? 'grabbing' : 'grab';
			}

			if (isPressed) {
				window.scrollTo((x - startX) * 1.5, (x - startX) * 1.5);
			}

			if (positionDiff && positionDiff !== 0) {
				const newDiff = positionDiff + (positionDiff > 0 ? -48 : 48);

				if ((positionDiff < 0 && newDiff > 0) || (positionDiff > 0 && newDiff < 0)) {
					Mouse.setState('positionDiff', 0);
				} else {
					window.scrollTo(newDiff, newDiff);
					Mouse.setState('positionDiff', newDiff);
				}
			}

			if (!isPressed && !isDragEnd) {
				Mouse.setState('positionDiff', x - startX);
				Mouse.setState('isDragEnd', true);
			}

			ImageWrapper.instance.render(offscreenCanvas, offscreenCtx);

			renderLayers(domElementLayers, true);

			Layer.instances.forEach((instance) => {
				instance.render(domElementLayers, layerCtx);
			});

			render(offscreenCanvas);

			offscreenCtx.restore();
			layerCtx.restore();

			requestAnimationFrame(canvasAnimation);
		};

		/**
		 * Preload assets, then run animation
		 */

		(async () => {
			Canvas.mount(domElement);
			Canvas.mount(domElementLayers);

			const LOAD_START = +new Date();
			console.log('loading...');

			if (window.innerWidth >= Canvas.MOBILE_WIDTH) {
				const imageInstances = ImageWrapper.MakeImageInstances([
					{
						name: 'about',
						src: 'images/About.jpg',
						target: '#about-landing',
						// text: ['01', 'About']
					},
					{
						name: 'projects',
						src: 'images/Projects.jpg',
						target: '#projects > section.landing',
						// text: ['02', 'Projects']
					},
					{
						name: 'team',
						src: 'images/Team.jpg',
						target: '#team > section.landing',
						// text: ['03', 'Team']
					},
					{
						name: 'contact',
						src: 'images/Contact.jpg',
						target: '#contact > section.landing',
						// text: ['04', 'Contact']
					},
					{
						type: 'secondary',
						name: 'britain',
						src: 'images/branches/britain.jpg',
						target: '#britain .branch__right',
					},
					{
						type: 'secondary',
						name: 'czech',
						src: 'images/branches/czech.jpg',
						target: '#czech .branch__right',
					},
					{
						type: 'secondary',
						name: 'denmark',
						src: 'images/branches/denmark.jpg',
						target: '#denmark .branch__right',
					},
					{
						type: 'secondary',
						name: 'iceland',
						src: 'images/branches/iceland.jpg',
						target: '#iceland .branch__right',
					}
				]);

				new ImageWrapper(imageInstances);
			}

			await FontsPromise();

			for (const imageInstance of ImageWrapper.instance.children) {
				await imageInstance.load();
			}

			const LOAD_END = (+new Date() - LOAD_START) / 1000;
			console.log('done after %s sec.', LOAD_END);

			new Decoration(document.querySelector('#about > section.intro > div.intro__bottom > div.intro__img > div.decoration')!, 4, 4, Decoration.INTRO);

			new Decoration(document.querySelector('#about > section.numbers.numbers--primary > div.decoration.hidden')!, 8, 4, Decoration.MIL41);

			new Decoration(document.querySelector('#about > section.numbers.numbers--black > div.decoration.hidden')!, 9, 4, Decoration.LOANS);

			new Decoration(document.querySelector('#about > section.numbers.numbers--secondary > div.decoration.hidden')!, 9, 4, Decoration.CUSTOMERS);

			new Decoration(document.querySelector('#contact > section.career > div.career__bottom > div.decoration')!, 9, 3, Decoration.CAREER);

			if (window.innerWidth < Canvas.MOBILE_WIDTH) {
				scrollWrapper.uninstall();
			} else {
				scrollWrapper.install();
			}

			scrollWrapper.intersectionText();

			if (window.innerWidth < Canvas.MOBILE_WIDTH) {
				setTimeout(() => {
					canvasAnimation();
				}, LOAD_END > 5 ? 0 : 5000 - LOAD_END * 1000);
			} else {
				WobbleLayer();

				if (!Canvas.isMS()) {
					MouseLayer();
				}

				LoaderLayer();

				setTimeout(() => {
					Layer.get('loader')!.data.done();

					canvasAnimation();

					setTimeout(() => {
						CanvasApp.afterLoadAnimation();
					}, 1);
				}, LOAD_END > 5 ? 0 : 5000 - LOAD_END * 1000);
			}

			/**
			 * Event bindings
			 * */

			window.addEventListener('scroll', () => {
				scrollWrapper.scrollPerc = window.pageXOffset / (scrollWrapper.scrollWidth - window.innerWidth);

				if (window.innerWidth >= Canvas.MOBILE_WIDTH) {
					scrollWrapper.arrangeSections();

					scrollWrapper.sectionLock();
				}

				scrollWrapper.intersectionText();

				scrollWrapper.updateScrollbar();

				Decoration.checkIntersection();
			}, false);

			window.addEventListener('resize', debounce(() => {
				// @ts-ignore
				window.scrollTo(0, 0, true);

				const isResized = Math.abs(domElement.width - window.innerWidth) > 40 || Math.abs(domElement.height - window.innerHeight) > 40;

				Canvas.mount(domElement);
				Canvas.mount(domElementLayers);

				const LOAD_START = +new Date();
				console.log('resizing...');

				if (isResized) {
					for (const imageInstance of ImageWrapper.instance.children) {
						imageInstance.getStaticPosition();
					}
				}

				scrollWrapper.scrollPerc = window.pageXOffset / (scrollWrapper.scrollWidth - window.innerWidth);

				if (window.innerWidth < Canvas.MOBILE_WIDTH) {
					scrollWrapper.uninstall();

					scrollWrapper.intersectionText();

					scrollWrapper.sectionLock();

					scrollWrapper.updateScrollbar();

					Decoration.checkIntersection();
				} else {
					if (isResized) {
						scrollWrapper.install();

						domElement.width = window.innerWidth;
						domElement.height = window.innerHeight;

						offscreenCanvas.width = window.innerWidth;
						offscreenCanvas.height = window.innerHeight;

						domElementLayers.width = window.innerWidth;
						domElementLayers.height = window.innerHeight;

						scrollWrapper.arrangeSections();

						scrollWrapper.intersectionText();

						scrollWrapper.sectionLock();

						scrollWrapper.updateScrollbar();

						Decoration.checkIntersection();
					}
				}

				const LOAD_END = (+new Date() - LOAD_START) / 1000;
				console.log('done after %s sec.', LOAD_END);
			}), false);

			if (/iPad/i.test(window.navigator.userAgent) && window.innerWidth > 1024) {
				window.addEventListener('touchmove', mousemove, false);
				window.addEventListener('touchstart', mousedown, false);
				window.addEventListener('touchend', mouseup, false);
			} else {
				window.addEventListener('touchmove', mousemove, false);
				window.addEventListener('touchstart', mousedown, false);
				window.addEventListener('touchend', mouseup, false);

				window.addEventListener('mousemove', mousemove, false);
				window.addEventListener('mousedown', mousedown, false);
				window.addEventListener('mouseup', mouseup, false);
			}

			Array.from(document.querySelectorAll('[data-cursor-except]')).forEach((cursorElement: Element) => {
				cursorElement.addEventListener('mouseenter', () => {
					Mouse.setState('isHovered', true);
				});

				cursorElement.addEventListener('mouseleave', () => {
					Mouse.setState('isHovered', false);
				});
			});

			Array.prototype.forEach.call(document.querySelectorAll('[data-nav-link]'), (scrollToButton: HTMLElement) => {
				scrollToButton.addEventListener('click', (e: Event) => {
					e.preventDefault();
					let target = scrollToButton.dataset.navLink

					if (!target) {
						return;
					}

					ScrollToSection.scrollTo(target);
				});
			}, false);
		})();

		window.onbeforeunload = function () {
			// @ts-ignore
			window.scrollTo(0,0, true);
		};
	}

	static afterLoadAnimation() {
		setTimeout(() => {
			document.querySelector('.intro .intro__img')!.classList.add('animate');
		}, 500);

		setTimeout(() => {
			document.querySelector('.scroll-drag__arrow')!.classList.add('animate');

			Decoration.checkIntersection();
		}, 1000);
	};
}
