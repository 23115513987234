const debounce: Function = (callback: any = (): any => null, wait: number = 100) => {
	if (typeof window === 'undefined') {
		return callback();
	}

	let timeout: any;

	return function (...args: any): void {
		// @ts-ignore
		const context = this;

		window.clearTimeout(timeout);
		timeout = window.setTimeout(() => callback.apply(context, args), wait);
	};
};

export default debounce;
