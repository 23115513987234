import Mouse from '@/js/components/canvas/core/mouse';
import Layer from '@/js/components/canvas/core/layer';

export default function mousedown(event: MouseEvent | TouchEvent) {
	event.preventDefault();

	Mouse.setState('isPressed', true);
	Mouse.setState('isDragEnd', false);

	const x = typeof (event as MouseEvent).clientX !== 'undefined' ?
		(event as MouseEvent).clientX : (event as TouchEvent).touches[0].clientX;

	const y = typeof (event as MouseEvent).clientY !== 'undefined' ?
		(event as MouseEvent).clientY : (event as TouchEvent).touches[0].clientY;

	Mouse.setState('startX', x);
	Mouse.setState('startY', y);

	const mouseLayer = Layer.get('mouse-cursor');

	if (mouseLayer) {
		mouseLayer.data.tick = 1;
	}
}
